.navbar-default {
  background-color: black;
  border-color: #0a0a0a;
}
.navbar-default .navbar-brand {
  color: #ffdd03;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #fbfcd4;
}
.navbar-default .navbar-text {
  color: #ffdd03;
}
.navbar-default .navbar-nav > li > a {
  color: #ffdd03;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #fbfcd4;
}
.navbar-default .navbar-nav > li > .dropdown-menu {
  background-color: #151515;
}
.navbar-default .navbar-nav > li > .dropdown-menu > li > a {
  color: #ffdd03;
}
.navbar-default .navbar-nav > li > .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav > li > .dropdown-menu > li > a:focus {
  color: #fbfcd4;
  background-color: #0a0a0a;
}
.navbar-default .navbar-nav > li > .dropdown-menu > li.divider {
  background-color: #0a0a0a;
}
.navbar-default .navbar-nav .open .dropdown-menu > .active > a,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
  color: #fbfcd4;
  background-color: #0a0a0a;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #fbfcd4;
  background-color: #0a0a0a;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #fbfcd4;
  background-color: #0a0a0a;
}
.navbar-default .navbar-toggle {
  border-color: #0a0a0a;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #0a0a0a;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #ffdd03;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #ffdd03;
}
.navbar-default .navbar-link {
  color: #ffdd03;
}
.navbar-default .navbar-link:hover {
  color: #fbfcd4;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #ffdd03;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fbfcd4;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fbfcd4;
    background-color: #0a0a0a;
  }
}