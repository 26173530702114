/*
Black gold colour scheme:
#151515
#0A0A0A
#FBFCD4
#FFDD03
#FBC403
*/


body {
   font-family: 'Lato', sans-serif;
   color: #0A0A0A;
   background-color: black;
}


.landingText {
    text-align: center;
    padding-top: 70px;
    padding-left: 10%;
    padding-right: 10%;
    position: absolute;
    top: 0;
    height: 100vh;
    font-size: 60px;
}

.blackDiv {
   background: #151515;
   color: #fbc403
}

.creamDiv {
   background: #FBFCD4;
   color: #0A0A0A;
}

.goldDiv {
   background: #ffdd03;
   color: #151515;
}

.homeText {
   padding: 5%;
   font-size: 30px;
}

@media only screen and (max-width: 1024px) {
    .homeText {
        font-size: 25px;
    }
    .landingText {
        font-size: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .homeText {
        font-size: 20px;
    }
    .landingText {
        font-size: 30px;
    }
}



.centered-container {
   height: 100vh;
   position: relative 
}             
.centered-content {
   margin: 0;
   position: absolute;               
   top: 50%;                         
   transform: translate(0, -50%) 
} 

.homeLogos img {
   width: auto;
   height: 250px;
   max-height: 250px;
   max-width: 100%;
   display: block;
   margin: 0 auto;
}

.carousel-control.left, .carousel-control.right {
    background-image: none
}

.footer {
   background-color: black;
   color: #FFDD03;
   text-align: center;
}

.card {
   /* Add shadows to create the "card" effect */
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
   transition: 0.3s;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
   box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.contactDiv {
   height: 86vh;
   padding-top: 10%;
}


.image-container {
    position: relative;
    text-align: center;
}

.image-centered {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: #FBFCD4;
   width: 80%;
   font-size: 60px;
}

.image-bottom-right {
   position: absolute;
   bottom: 8px;
   right: 16px;
 }

@media only screen and (max-width: 1024px) {
   .image-centered {
      font-size: 50px;
   }
}
@media only screen and (max-width: 768px) {
   .image-centered {
      font-size: 40px;
   }
}

@media only screen and (max-width: 500px) {
   .image-centered {
      font-size: 30px;
   }
}
@media only screen and (max-width: 375px) {
   .image-centered {
      font-size: 20px;
   }
}

